import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogRef, DynamicDialogModule, DynamicDialogConfig } from 'primeng/dynamicdialog';


import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { RippleModule } from 'primeng/ripple';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { SearchInputComponent } from '../form/search-input/search-input.component';
import { Platform } from '@angular/cdk/platform';
import { Observable, Subject, Subscription, map, take, takeUntil, tap } from 'rxjs';
import { BootstrapGridBreakpoints, BreakpointsService } from '../../services/breakpoints.service';
import { UserInnerMenuComponent } from '../user-inner-menu/user-inner-menu.component';
import { UserImageBlockComponent } from '../user-image-block/user-image-block.component';
import { MenuModule } from 'primeng/menu';
import {  IAuthMe, IProfile } from '../../models/auth/interfaces/auth.interfaces';
import { AuthService } from '../../models/auth/auth.service';
import { SkeletonModule } from 'primeng/skeleton';
import { StateService } from '../../services/state.service';
import { MenuItem, MessageService } from 'primeng/api';
import { OrderState } from '../../api/orders/orders.interface';
import { BadgeModule } from 'primeng/badge';
import { getMessaging, Messaging, onMessage } from '@angular/fire/messaging';
import { environment } from '../../../../environments/environment';
import { getToken } from '@firebase/messaging';
import { ChatService } from '../../api/chat/chat.service';
import { IContentResponse } from '../../interfaces/global.interfaces';
import { Chat, IChatsParams } from '../../api/chat/chat.interfaces';
import { SwPush } from "@angular/service-worker";
import { PushTokensService } from '../../services/push-tokens.service';
import { SwitchControlComponent } from '../form/switch-control/switch-control.component';
import { ToastSeverity } from '../notification/notification.component';
@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ToolbarModule,
    InputTextModule,
    SidebarModule,
    RippleModule,
    DynamicDialogModule,
    RouterLink,
    SearchInputComponent,
    UserInnerMenuComponent,
    UserImageBlockComponent,
    MenuModule,
    SkeletonModule,
    BadgeModule,
    SwitchControlComponent
],
    providers: [
      DialogService, DynamicDialogRef, DynamicDialogConfig
    ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();


  public userProfile$!: Observable<IProfile>;

  public selectedVehicle: boolean = false;
  public searchControl: FormControl = new FormControl('');
  private previousScroll = 0;
  public navHidden = false;
  public navbarOpen = false;
  public stickyNav = false;
  public userMobileMenuVisible: boolean = false;
  @HostListener("window:scroll", [])
  onScroll() {
    if (typeof window !== "undefined") {
      if (this.previousScroll < window.scrollY) {
        if (window.scrollY > 100) {
          if (!this.navbarOpen) {
            this.navHidden = true;
            this.stickyNav = false;
          } else {
            this.navHidden = false;
            this.stickyNav = true;
          }
        }
      } else {
        if (window.scrollY < 100) {
          this.navHidden = false;
          this.stickyNav = false;
        } else {
          this.stickyNav = true;
          this.navHidden = false;
        }
      }

    }
    this.previousScroll = window.scrollY;
  }


  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.searchControl.value && this.searchControl.value !== '') {
      this.searchProducts(this.searchControl.value);
    }
  }
  public mobileMenuIsOpened: boolean = false;
  public addVehicleBottomSheetOpened: boolean = false;
  private loginDialogRef: DynamicDialogRef | undefined;
  private addVehicleDialogRef: DynamicDialogRef | undefined;
  private lgSubscription: Subscription = new Subscription;
  public lgLayout: boolean = true;


  public hasUnreadMessages$: Observable<boolean>;
  public unreadMessagesCount$: Observable<number>;
  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter<any>(); 



  public userMenu: MenuItem[] | undefined = [
    
    {
      
      label: 'User menu',
      items: [
          {
              label: 'Account',
              icon: 'pi pi-user-edit',
              command: () => {
                 this.router.navigate(['/admin/account/summary'])
              }
          },
          {
              label: 'Log out',
              icon: 'pi pi-sign-out',
              command: () => {
                  this.logOut();
              }
          }
      ]
  },
  ]

  public notificationsControl: FormControl = new FormControl(false);
  public notificationsEnabled$: Observable<boolean>;

  constructor(public dialogService: DialogService, 
    private platform: Platform, private breakpointsService: BreakpointsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
    private fcmMessaging: Messaging,
    private swPush: SwPush,
    private chatService: ChatService,
    private pushTokensService: PushTokensService,
  private messageService: MessageService){

  }


  ngOnInit(): void {
    this.subscribeToNotificationsPermissionChanges();
    this.hasUnreadMessages$ = this.stateService.select((state) => state.hasUnreadMessages);
    this.unreadMessagesCount$ = this.stateService.select((state) => state.unreadMessagesCount);
    this.setUnreadChatState();
    this.getActiveUser();
    if(this.platform.isBrowser){
      this.lgSubscription = this.breakpointsService.isBreakpointOrLower(BootstrapGridBreakpoints.LGLabel).subscribe({
        next: (matches: boolean) => {
            this.lgLayout = matches;
        }
      } 
      )
    }

    this.listenToNotifications();
  //  this.subscribeToPush();
  }




  subscribeToNotificationsPermissionChanges(){
    this.stateService.select((state) => state.notificationsEnabled).pipe(takeUntil(this.unsubscribe$)).subscribe((value: boolean) => {
        this.notificationsControl.setValue(value);
    });
  }
  subscribeToPush(): void {
    this.swPush.messages.subscribe(
      (res: any) => {
        console.log(res, " Message to show in the notificaiton ");
      }
    );
  }

  
  requestPermission() {
    if(this.platform.IOS){
      this.pushTokensService.requestPermissionForNotifications(this.authService?.authMe?.profile as IProfile);
    } else {
      if(Notification.permission === 'granted'){
        this.messageService.add({severity: ToastSeverity.Info, key: 'notifications', detail: 'To disable notifications, please go to your browser settings.' });
       // this.stateService.setState('notificationsEnabled', false);
        // i need to disable permission in browser
      } else {
        this.pushTokensService.requestPermissionForNotifications(this.authService?.authMe?.profile as IProfile);
      }
    }
  }

  listenToNotifications() {
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
    //  payload: {data: chat , order, } notification title body
     // this.messageService.add({ styleClass: 'p-toast-message-custom', severity: ToastSeverity.Info, key: 'chat', summary: payload.notification?.title, detail: payload.notification?.body });
      this.setUnreadChatState();
    })
  }


  private logOut(){
    this.authService.logout();
  }

  public toggleSidebarEvent(){
    this.toggleSidebar.emit();
  }

  private getActiveUser(){
    this.userProfile$ = this.authService.getCurrentUser().pipe(
    take(1),
    tap((data: IAuthMe) => {
      this.pushTokensService.addPushTokenForThisDevice(data.profile);
    }),
     map((data) => data.profile as IProfile));
  }



  public searchProducts(searchTerm: string){
    this.router.navigate(['/admin/orders'], {
      queryParams: { search: searchTerm, state: OrderState.All },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
  });
  this.searchControl.setValue('', { emitEvent: false });
  }

  public goToUserPage(){
    this.router.navigate(['/user/account']);
  }




  public showAddVehicleBottomSheet(){
    this.addVehicleBottomSheetOpened = true;
  }

  public closeBottomSheet(event:any){
    if(event){
      this.selectedVehicle = true;
      
    } else {
      this.selectedVehicle = false;
    }
    this.addVehicleBottomSheetOpened = false;
  }

  public addSelectedVehicle(event: any){
    if(event){
      this.selectedVehicle = true;
    } else {
      this.selectedVehicle = false;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.loginDialogRef) {
      this.loginDialogRef.destroy();
  }
    if (this.addVehicleDialogRef) {
        this.addVehicleDialogRef.destroy();
    }
    if(this.lgSubscription){
      this.lgSubscription.unsubscribe();
    }
}


private setUnreadChatState(){
  const params: IChatsParams = {
    offset: 0,
    limit: 999
  }

this.chatService.getAll(params).pipe(take(1)).subscribe({
    next: (response: IContentResponse<Chat>) => {
      let unreadMessagesCount = 0;
      
      response.items.forEach((chat: Chat) => {
        if (chat.userSummary?.noticeCount && chat.userSummary?.noticeCount > 0) {
          unreadMessagesCount += 1;
        }
      });
      
       const hasUnreadMessages = unreadMessagesCount > 0;
       this.stateService.setState('hasUnreadMessages', hasUnreadMessages);
       this.stateService.setState('unreadMessagesCount', unreadMessagesCount);

    },
  });
}




}

